import { useCallback } from "react";
import {
  QueryClient,
  skipToken,
  useMutation,
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { v4 as uuidv4 } from "uuid";

import {
  uploadFile,
  getWorkspace,
  createAlbum,
  deleteAsset,
  patchAlbum,
  updateAssetMetadata,
  createCollection,
  patchCollection,
  getAlbum,
  deleteAlbum,
  getAsset,
  createShare,
  getShares,
  getAssets,
  patchShare,
  inviteToShare,
  upsertShareQuery,
  deleteShareQuery,
  getAlbums,
  getSharesForUser,
  getSharesForAlbum,
  getShare,
  getSharesForAsset,
  deleteCollection,
  deleteShare,
  unInviteFromShare,
} from "./apiClient";

import { activeUploads, FileUpload, UploadStatus } from "../atoms";
import {
  Album,
  CollectionBase,
  InviteToShareParameters,
  PatchAlbumParameters,
  PatchCollectionParameters,
  PatchShareParameters,
  Share,
  ShareQueryUpsert,
  UnInviteFromShareParameters,
  Label,
  Tag,
  GetAssetsParams,
  GetAlbumsParams,
} from "./types";
import { useAtom } from "jotai";

export function useWorkspace(): {
  collections: CollectionBase[];
  hasOrganization: boolean;
  loading: boolean;
  error: boolean;
} {
  // TODO: "The" react way would be Suspense and ErrorBoundary..
  const workspaceQuery = useQuery({
    queryKey: ["workspace"],
    queryFn: async () => {
      return await getWorkspace();
    },
  });
  return {
    collections: workspaceQuery.data?.collections ?? [],
    hasOrganization: workspaceQuery.data?.hasOrganization ?? false,
    loading: workspaceQuery.isLoading,
    error: workspaceQuery.isError,
  };
}

export const refreshWorkspace = (queryClient: QueryClient) => {
  queryClient.invalidateQueries({ queryKey: ["workspace"] });
};

export function useCollections() {
  const queryClient = useQueryClient();

  const _createCollection = useMutation({
    mutationFn: async (name: string): Promise<CollectionBase> => {
      const collection = await createCollection(name);
      refreshWorkspace(queryClient);
      return collection;
    },
  });

  const _deleteCollection = useMutation({
    mutationFn: async (collectionId: string) => {
      await deleteCollection(collectionId);
      refreshWorkspace(queryClient);
    },
  });

  return {
    deleteCollection: _deleteCollection,
    createCollection: _createCollection,
  };
}

export function useCollection(collectionId?: string) {
  const queryClient = useQueryClient();
  const workspace = useWorkspace();
  const collection = workspace.collections.filter(
    (collection) => collection.id === collectionId,
  );
  const _patchCollection = useMutation({
    mutationFn: async (newValues: PatchCollectionParameters) => {
      patchCollection(newValues.name, newValues.collectionId);
    },
    onSuccess: () => refreshWorkspace(queryClient),
    onError: (err) => {
      console.error(err);
      refreshWorkspace(queryClient);
    },
  });

  return {
    patchCollection: _patchCollection,
    collection: { name: collection[0]?.name, id: collection[0]?.id },
  };
}

export function useUploads(albumId: string, collectionId: string) {
  const queryClient = useQueryClient();
  const [atomFiles, setAtomFiles] = useAtom(activeUploads);

  function handleFileSelect(evt: React.FormEvent<HTMLInputElement>) {
    const fileInput = Array.from(evt.currentTarget.files || []);
    const newFiles: FileUpload[] = fileInput
      .filter(
        (f: File) =>
          !atomFiles.some(
            (enquedFile) =>
              enquedFile.state !== UploadStatus.FAILURE &&
              enquedFile.file.name === f.name,
          ),
      )
      .map((f: File) => ({
        file: f,
        albumId: albumId,
        collectionId: collectionId,
        state: UploadStatus.IN_PROGRESS,
        id: uuidv4(),
      }));

    setAtomFiles(atomFiles.concat(newFiles));

    newFiles.forEach((fileUpload) => {
      uploadFile(fileUpload)
        .then((response) => {
          setAtomFiles((currentFiles) => {
            const fileIndex = currentFiles.indexOf(fileUpload);
            const newAtomFiles = [
              ...currentFiles.slice(0, fileIndex),
              { ...fileUpload, state: UploadStatus.SUCCESS },
              ...currentFiles.slice(fileIndex + 1),
            ];
            return newAtomFiles;
          });
        })
        .catch((error) => {
          console.error(error);
          setAtomFiles((currentFiles) => {
            const fileIndex = currentFiles.indexOf(fileUpload);
            const newAtomFiles = [
              ...currentFiles.slice(0, fileIndex),
              { ...fileUpload, state: UploadStatus.FAILURE },
              ...currentFiles.slice(fileIndex + 1),
            ];
            return newAtomFiles;
          });
        })
        .finally(async () => {
          refreshAssets(queryClient);
        });
    });
  }

  const removeFile = useCallback(
    (filename: string) => {
      setAtomFiles((atomFiles) =>
        atomFiles.filter((fileUpload) => fileUpload.file.name !== filename),
      );
    },
    [setAtomFiles],
  );

  return { atomFiles, handleFileSelect, removeFile };
}

export function useAlbums({
  collectionId,
  limit,
  offset,
  searchQuery,
  sortOrder,
}: GetAlbumsParams) {
  const albums = useSuspenseQuery({
    queryKey: ["albums", collectionId, limit, offset, searchQuery, sortOrder],
    queryFn: collectionId
      ? ({ signal }) =>
          getAlbums(collectionId, limit, offset, searchQuery, sortOrder, signal)
      : () => ({ albums: [], albumsCount: 0 }),
    gcTime: 60,
  });

  return {
    albums: albums.data,
  };
}

export function useAlbumsNoSuspense({
  collectionId,
  limit,
  offset,
  searchQuery,
  sortOrder,
}: GetAlbumsParams) {
  const albums = useQuery({
    queryKey: ["albums", collectionId, limit, offset, searchQuery, sortOrder],
    queryFn: collectionId
      ? ({ signal }) =>
          getAlbums(collectionId, limit, offset, searchQuery, sortOrder, signal)
      : skipToken,
    gcTime: 60,
  });

  return {
    albums: albums.data,
  };
}

export const refreshAlbums = (queryClient: QueryClient) => {
  queryClient.invalidateQueries({ queryKey: ["albums"] });
};

export function useCreateAlbum(collectionId: string) {
  const queryClient = useQueryClient();
  const _createAlbum = useMutation({
    mutationFn: async (name: string): Promise<Album> => {
      return await createAlbum(collectionId, name);
    },
    onSuccess: () => refreshAlbums(queryClient),
  });
  return { createAlbum: _createAlbum };
}

export function useDeleteAlbum(collectionId: string) {
  const queryClient = useQueryClient();
  const _deleteAlbum = useMutation({
    mutationFn: async (albumId: string) => {
      await deleteAlbum(collectionId, albumId);
    },
    onSuccess: () => refreshAlbums(queryClient),
  });
  return { deleteAlbum: _deleteAlbum };
}

export function useAlbumShares(collectionId: string, albumId: string) {
  const query = useQuery({
    queryKey: ["albumShares", collectionId, albumId],
    queryFn: () => getSharesForAlbum(collectionId, albumId),
    throwOnError: true,
  });
  return { shares: query.data };
}

export function useAlbum(collectionId: string, albumId: string) {
  const queryClient = useQueryClient();
  const albumQuery = useQuery({
    queryKey: ["albumById", collectionId, albumId],
    queryFn: () => getAlbum(collectionId, albumId),
    throwOnError: true,
  });
  const refreshAlbum = albumQuery.refetch;
  const refreshAlbumSharedIn = () => {
    queryClient.invalidateQueries({
      queryKey: ["albumShares", collectionId, albumId],
    });
  };

  const _patchAlbum = useMutation({
    mutationFn: async (newValues: PatchAlbumParameters) => {
      await patchAlbum(collectionId, albumId, newValues);
    },
    onSuccess: async () => {
      await refreshAlbum();
      refreshAlbums(queryClient);
      refreshAssets(queryClient);
      refreshAlbumSharedIn();
    },
    onError: async (err) => {
      console.error(err);
      await refreshAlbum();
      refreshAlbums(queryClient);
      refreshAssets(queryClient);
      refreshAlbumSharedIn();
    },
  });

  return { patchAlbum: _patchAlbum, album: albumQuery.data };
}

export function useAssets(
  {
    collectionId,
    albumId,
    searchQuery,
    offset,
    limit,
    sortOrder,
    shareId,
  }: GetAssetsParams,
  gcTime?: number,
) {
  const fetchedAssetsQuery = useSuspenseQuery({
    queryKey: [
      "assets",
      collectionId,
      albumId,
      searchQuery,
      offset,
      limit,
      sortOrder,
      shareId,
    ],
    queryFn: async ({ signal }) => {
      if (!albumId && !searchQuery) {
        // kind of hacky: on the collection view, if there is no search query, we don't want to show assets
        return { assets: [], assetsCount: 0 };
      }
      return await getAssets({
        collectionId,
        albumId,
        searchQuery,
        offset: offset,
        limit,
        sortOrder,
        signal,
        shareId,
      });
    },
    gcTime: gcTime || 60,
  });

  return {
    assets: fetchedAssetsQuery.data,
    refetchAssets: fetchedAssetsQuery.refetch,
    isLoading: fetchedAssetsQuery.isLoading,
  };
}

export const refreshAssets = (queryClient: QueryClient) => {
  queryClient.invalidateQueries({ queryKey: ["assets"] });
};

export function useAsset({
  assetId,
  albumId,
  collectionId,
}: {
  assetId: string;
  albumId: string;
  collectionId: string;
}) {
  const queryClient = useQueryClient();

  const assetQuery = useSuspenseQuery({
    queryKey: ["assetById", collectionId, albumId, assetId],
    queryFn: () => getAsset(collectionId, albumId, assetId),
  });

  const _deleteAsset = useMutation({
    mutationFn: async () => {
      await deleteAsset(collectionId, albumId, assetId);
    },
    onSuccess: () => refreshAssets(queryClient),
  });

  const _updateAssetMetadata = useMutation({
    mutationFn: async (metadata: {
      labels?: Label[];
      tags?: Tag[];
      name?: String;
    }) => {
      if (!collectionId || !albumId) {
        return;
      }
      await updateAssetMetadata(metadata, collectionId, albumId, assetId);
    },
    onSuccess: () => {
      refreshAssets(queryClient);
      assetQuery.refetch();
      queryClient.invalidateQueries({
        queryKey: ["sharesForAsset", collectionId, albumId, assetId],
      });
    },
  });

  return {
    asset: assetQuery.data,
    deleteAsset: _deleteAsset,
    updateAssetMetadata: _updateAssetMetadata,
  };
}

export function useAssetShares({
  assetId,
  albumId,
  collectionId,
}: {
  assetId: string;
  albumId: string;
  collectionId: string;
}) {
  const assetSharesQuery = useSuspenseQuery({
    queryKey: ["sharesForAsset", collectionId, albumId, assetId],
    queryFn: () => getSharesForAsset(collectionId, albumId, assetId),
  });
  return { sharesForAsset: assetSharesQuery.data };
}

export const refreshShares = (queryClient: QueryClient) => {
  queryClient.invalidateQueries({ queryKey: ["shares"] });
};

export function useShares() {
  const queryClient = useQueryClient();
  const sharesQuery = useQuery({
    queryKey: ["shares"],
    queryFn: () => getShares(),
    throwOnError: true,
  });
  const userSharesQuery = useQuery({
    queryKey: ["userShares"],
    queryFn: () => getSharesForUser(),
    throwOnError: true,
  });

  const _createShare = useMutation({
    mutationFn: async (name: string): Promise<Share> => {
      const share = await createShare(name);
      refreshShares(queryClient);
      return share;
    },
    onSuccess: () => {
      refreshShares(queryClient);
    },
    onError: (error: Error) => {
      console.error(error);
    },
  });

  return {
    createShare: _createShare,
    userShares: userSharesQuery.data,
    shares: sharesQuery.data,
  };
}

export function useShare(shareId?: string) {
  const queryClient = useQueryClient();

  const shareQuery = useQuery({
    queryKey: ["shareById", shareId],
    queryFn: shareId ? () => getShare(shareId) : skipToken, //can we use the shares query and use a selector to pick out the shareId to minimize fetching and use the cache correctly?
    throwOnError: true,
  });

  const _deleteShareQuery = useMutation({
    mutationFn: (payload: { shareQueryId: string; shareId: string }) =>
      deleteShareQuery(payload.shareQueryId, payload.shareId),
    onSuccess: () => {
      refreshShares(queryClient);
    },
    onError: (error: Error) => {
      console.error(error);
    },
  });

  const _upsertShareQuery = useMutation({
    mutationFn: (payload: ShareQueryUpsert) => {
      return upsertShareQuery(payload);
    },
    onSuccess: () => {
      shareQuery.refetch();
    },
    onError: (err) => {
      console.error(err);
      shareQuery.refetch();
    },
  });

  const _patchShare = useMutation({
    mutationFn: (payload: PatchShareParameters) => {
      return patchShare(payload.name, payload.shareId)
        .then(() => {
          shareQuery.refetch();
          refreshShares(queryClient);
        })
        .catch((err) => {
          console.error(err);
          shareQuery.refetch();
          refreshShares(queryClient);
        });
    },
  });

  const _inviteToShare = useMutation({
    mutationFn: async (payload: InviteToShareParameters) => {
      try {
        await inviteToShare(payload.emails, payload.shareId);
        await shareQuery.refetch();
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
  });

  const _unInviteFromShare = useMutation({
    mutationFn: (payload: UnInviteFromShareParameters) =>
      unInviteFromShare(payload.email, payload.shareId),
    onSuccess: () => {
      refreshShares(queryClient);
    },
  });

  const _deleteShare = useMutation({
    mutationFn: async (shareId: string) => {
      await deleteShare(shareId);
    },
    onSuccess: () => {
      refreshShares(queryClient);
    },
    onError(error, variables, context) {
      console.error("Error deleting share", error);
      refreshShares(queryClient);
    },
  });

  return {
    inviteToShare: _inviteToShare,
    unInviteFromShare: _unInviteFromShare,
    patchShare: _patchShare,
    upsertShareQuery: _upsertShareQuery,
    deleteShareQuery: _deleteShareQuery,
    share: shareQuery.data,
    deleteShare: _deleteShare,
  };
}
