import "twin.macro";
import React, { startTransition, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";

import { pageSize } from "../../clients/apiClient";
import { useAlbums, useAssets } from "../../clients/apiHooks";

import { useIntersectionObserver } from "../../hooks";
import { CheckboxRoundButton, MenuContainer, NeuButton } from "../generic/Neu";
import { getPage, Pagination } from "../generic/Pagination";
import SearchInput from "../generic/SearchInput";
import { Album, SortOrder } from "../../clients/types";
import { AlbumDisplayList, AssetsDisplayList } from "../generic/DisplayList";
import { SuspenseWrapper } from "../generic/SuspenseWrapper";
import { ErrorBoundary } from "@sentry/react";

export interface AlbumListProps {
  collectionId: string;
  filter?: string;
  isCondensed?: boolean;
}

export const renderInputComponent = (
  inputProps: React.InputHTMLAttributes<HTMLInputElement>,
): JSX.Element => (
  <div tw="relative">
    <i className="mi-search absolute top-2 left-2" />
    <input
      {...inputProps}
      tw="pl-8 pr-2 w-full h-12 -mt-1 rounded focus:outline-none bg-[#fafafa] border border-[#a8a8a8]"
      placeholder="Search"
    />
  </div>
);

export const resetPageParam = ({
  searchParams,
  setSearchParams,
  name,
}: {
  searchParams: URLSearchParams;
  setSearchParams: (
    params: URLSearchParams,
    options?: { replace: boolean },
  ) => void;
  name: string;
}) => {
  if (searchParams.has(name)) {
    const newParams = new URLSearchParams(searchParams.toString());
    newParams.delete(name);
    setSearchParams(newParams, { replace: true });
  }
};

function AlbumListInner({
  collectionId,
  isCondensed,
}: AlbumListProps): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = getPage(searchParams);
  const [searchQuery, setSearchQuery] = useState("");
  function _setSearchQuery(query: string) {
    startTransition(() => {
      setSearchQuery(query);
    });
  }
  const [sortOrder, setSortOrder] = useState<SortOrder>("ASC");
  const { assets } = useAssets({
    collectionId,
    offset: (page - 1) * pageSize,
    searchQuery,
    sortOrder,
  });
  const { albums } = useAlbums({
    collectionId,
    limit: pageSize,
    offset: (page - 1) * pageSize,
    searchQuery,
    sortOrder,
  });
  const albumPages = Math.max(Math.ceil(albums.albumsCount / pageSize), 1);
  const assetPages = Math.max(Math.ceil(assets.assetsCount / pageSize), 1);

  const { ref, isVisible } = useIntersectionObserver();

  const [isAlbumsChecked, setIsAlbumsChecked] = useState(true);
  const [isAssetsChecked, setIsAssetsChecked] = useState(true);

  useEffect(() => {
    resetPageParam({ searchParams, setSearchParams, name: "page" });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we intentionally don't add searchParams here, doing so would make it impossible to ever change the page!
  }, [searchQuery, isAlbumsChecked, isAssetsChecked]);

  useEffect(() => {
    if (!searchQuery) {
      setIsAssetsChecked(true);
      setIsAlbumsChecked(true);
      return;
    }
  }, [collectionId, searchQuery, sortOrder, page, isAssetsChecked]);

  return (
    <div>
      <div tw="mb-6 sm:mt-24 z-10 sticky top-12">
        <MenuContainer highlight={!isVisible}>
          <div tw="flex flex-col gap-4 sm:flex-row sm:gap-8">
            {isCondensed ? (
              <NeuButton to={`/collection/${collectionId}`}>
                <i className="mi-search" />
                {!isCondensed && <span> Search</span>}
              </NeuButton>
            ) : (
              <SearchInput
                collectionId={collectionId}
                searchQuery={searchQuery}
                setSearchQuery={_setSearchQuery}
              />
            )}

            {searchQuery && (
              <div tw="flex flex-row space-x-4">
                <CheckboxRoundButton
                  id="albums"
                  label="Albums"
                  checked={isAlbumsChecked}
                  onChange={() => setIsAlbumsChecked(!isAlbumsChecked)}
                />
                <CheckboxRoundButton
                  id="assets"
                  label="Assets"
                  checked={isAssetsChecked}
                  onChange={() => setIsAssetsChecked(!isAssetsChecked)}
                />
              </div>
            )}

            <div tw="flex flex-row gap-x-4">
              <NeuButton
                onClick={() =>
                  startTransition(() =>
                    setSortOrder(sortOrder === "ASC" ? "DESC" : "ASC"),
                  )
                }
              >
                <i className="mi-sort" />
                {!isCondensed && <span>Sort</span>}
              </NeuButton>

              <Link to={`/collection/${collectionId}/new-album`}>
                <NeuButton>
                  <i className="mi-add" />{" "}
                  {!isCondensed && <span>New album</span>}
                </NeuButton>
              </Link>
            </div>
          </div>
        </MenuContainer>
      </div>
      {isAlbumsChecked && (
        <div>
          <AlbumDisplayList
            ref={ref}
            albums={albums.albums}
            isCondensed={isCondensed}
            page={page}
            searchQuery={searchQuery}
          />
          {assets.assets.length === 0 || !isAssetsChecked || isCondensed ? (
            <Pagination pages={albumPages} tw="mt-4" />
          ) : (
            albumPages > 1 && (
              <NeuButton onClick={() => setIsAssetsChecked(false)} tw="mt-4">
                see more albums
              </NeuButton>
            )
          )}
        </div>
      )}
      {!isCondensed &&
        assets.assets.length > 0 &&
        isAssetsChecked &&
        searchQuery && (
          <div>
            <AssetsDisplayList
              assets={assets.assets}
              searchQuery={searchQuery}
            />
            {albums.albums.length === 0 || !isAlbumsChecked ? (
              <Pagination pages={assetPages} tw="mt-4" />
            ) : (
              assetPages > 1 && (
                <NeuButton onClick={() => setIsAlbumsChecked(false)}>
                  see more assets
                </NeuButton>
              )
            )}
          </div>
        )}
    </div>
  );
}

export default function AlbumList(props: AlbumListProps): JSX.Element {
  return (
    <ErrorBoundary
      fallback={<div>Something went wrong loading the album...</div>}
    >
      <SuspenseWrapper>
        <AlbumListInner {...props} />
      </SuspenseWrapper>
    </ErrorBoundary>
  );
}

interface AlbumLinkProps {
  album: Album;
  pageNumber?: number;
  imageUrls?: string[];
  isCondensed?: boolean;
  shareId?: string;
}

export function AlbumLink(props: AlbumLinkProps): JSX.Element {
  const a = props.album;
  const shareId = props.shareId;
  const route = shareId
    ? `/share/${shareId}/c/${a.collectionId}`
    : `/collection/${a.collectionId}`;

  return (
    <Link
      key={a.id}
      to={`${route}/a/${a.id}?page=${props.pageNumber}`}
      tw="w-full overflow-hidden transition-all px-2 py-2 shadow-card hover:pr-6 hover:-mx-2 rounded relative block"
      style={{ height: props.isCondensed ? "3.5rem" : "6rem" }}
    >
      <div tw="absolute top-0 left-0 flex w-full">
        {props.imageUrls
          ?.slice(0, 5)
          .map((url) => (
            <img
              key={`${url + " key"}`}
              tw="min-h-full min-w-[50px] grow shrink"
              src={`${url}?w=350&h=350`}
              alt={a.name}
            />
          ))}
      </div>
      <div tw="absolute top-0 left-0 w-full h-full bg-gradient-to-br md:bg-gradient-to-r from-black to-transparent" />
      <div tw="relative text-bgbase">
        <h2
          tw="mt-0 truncate leading-tight"
          style={{ fontSize: props.isCondensed ? "1.17rem" : undefined }}
        >
          {a.name}
        </h2>
      </div>
    </Link>
  );
}

export function RichAlbumLink(props: AlbumLinkProps): JSX.Element {
  const { ref, isVisible } = useIntersectionObserver();

  return (
    <div ref={ref}>
      {isVisible ? (
        <SuspenseWrapper
          fallback={
            <AlbumLink
              isCondensed={props.isCondensed}
              album={props.album}
              shareId={props.shareId}
            />
          }
        >
          <AlbumLinkAssetLoader
            isCondensed={props.isCondensed}
            album={props.album}
            pageNumber={props.pageNumber}
            shareId={props.shareId}
          />
        </SuspenseWrapper>
      ) : (
        <AlbumLink
          isCondensed={props.isCondensed}
          album={props.album}
          shareId={props.shareId}
        />
      )}
    </div>
  );
}

function AlbumLinkAssetLoader(props: AlbumLinkProps): JSX.Element {
  const a = props.album;
  const { assets } = useAssets({
    collectionId: a.collectionId!,
    albumId: a.id,
    limit: 5,
    sortOrder: "DESC",
  });

  return (
    <AlbumLink
      isCondensed={props.isCondensed}
      album={a}
      imageUrls={
        assets.assets.map((a) => a.url ?? "").filter((url) => url) || []
      }
      pageNumber={props.pageNumber}
      shareId={props.shareId}
    />
  );
}
