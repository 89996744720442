import { useNavigate } from "react-router-dom";
import { useShares, useWorkspace } from "../../clients/apiHooks";
import CollectionList from "./CollectionList";
import ShareList from "./ShareList";
import { useEffect } from "react";

export function LandingPage() {
  const { collections } = useWorkspace();

  const { shares, userShares } = useShares();

  const navigate = useNavigate();

  useEffect(() => {
    const isLargerScreen = window.matchMedia("(min-width: 1024px)").matches;

    if (isLargerScreen) {
      if (collections && collections.length > 0) {
        navigate(`/collection/${collections[0].id}`, { replace: true });
      } else if (shares && shares.length > 0) {
        navigate(`/share/${shares[0].id}`, { replace: true });
      } else if (userShares && userShares.length > 0) {
        navigate(`/share/${userShares[0].id}`, { replace: true });
      }
    }
  }, [collections, navigate, shares, userShares]);

  return (
    <div>
      <CollectionShareList />
    </div>
  );
}

export function CollectionShareList() {
  return (
    <div>
      <CollectionList />
      <ShareList />
    </div>
  );
}
