import { forwardRef } from "react";
import "twin.macro";
import { AlbumLink, RichAlbumLink } from "../collection/AlbumList";
import { Album, Asset } from "../../clients/types";
import AssetTile from "../album/AssetTile";
import ImageSkeleton from "./ImageSkeleton";
import React from "react";
import { pageSize } from "../../clients/apiClient";
import { SuspenseWrapper } from "./SuspenseWrapper";

interface AlbumDisplayListProps {
  albums: Album[];
  isCondensed?: boolean;
  page: number;
  searchQuery?: string;
  shareId?: string;
}
const NoMatchesMessage = ({ searchQuery }: { searchQuery: string }) => (
  <p>The search returned no matches for "{searchQuery}"</p>
);

export const AlbumDisplayList = forwardRef<
  HTMLSpanElement,
  AlbumDisplayListProps
>(({ albums, isCondensed, page, searchQuery, shareId }, ref) => {
  if (searchQuery && albums.length === 0) {
    return <NoMatchesMessage searchQuery={searchQuery} />;
  }

  if (albums.length === 0) {
    return <div>This collection contains no albums.</div>;
  }

  return (
    <div>
      <span ref={ref} tw="label mb-4">
        Albums
      </span>
      <div tw="space-y-4">
        {albums.map((a) => (
          <SuspenseWrapper
            key={a.id}
            fallback={<AlbumLink album={a} shareId={shareId} />}
          >
            <RichAlbumLink
              album={a}
              isCondensed={isCondensed}
              pageNumber={page}
              shareId={shareId}
            />
          </SuspenseWrapper>
        ))}
      </div>
    </div>
  );
});

export function AssetsDisplayList({
  assets,
  searchQuery,
  isLoading,
}: {
  assets: Asset[];
  searchQuery?: string;
  isLoading?: boolean;
}) {
  const renderContent = () => {
    if (isLoading && assets.length === 0) {
      return <LoadingSkeletons count={pageSize} />;
    }

    if (searchQuery && assets.length === 0) {
      return <NoMatchesMessage searchQuery={searchQuery} />;
    }

    if (assets.length === 0) {
      return <div>This Album contains no assets.</div>;
    }

    if (assets.length > 0) {
      return assets.map((asset) => <AssetItem key={asset.id} asset={asset} />);
    }

    return null;
  };

  return (
    <div>
      <span tw="label mt-6 mb-4">Assets</span>
      <div tw="grid grid-cols-2 sm:grid-cols-3 gap-6">{renderContent()}</div>
    </div>
  );
}

const LoadingSkeletons = ({ count }: { count: number }) => (
  <>
    {Array.from({ length: count }).map((_, i) => (
      <ImageSkeleton key={i} />
    ))}
  </>
);

const AssetItem = ({ asset }: { asset: Asset }) => (
  <div tw="flex">
    <AssetTile asset={asset} />
    <div tw="w-10 relative overflow-hidden">
      <span tw="pl-4 absolute bottom-0 left-0 inline-block text-sm font-bold -rotate-90 origin-bottom-left whitespace-nowrap translate-x-6">
        {asset.name}
      </span>
    </div>
  </div>
);
