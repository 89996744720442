import "twin.macro";

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useShares } from "../../clients/apiHooks";

import ResourceInput from "../generic/ResourceInput";
import { BackButton } from "../generic/Neu";
import { MenuCol, MarginWrapper, MainCol } from "../generic/Layout";
import { ErrorBoundary } from "@sentry/react";
import { CollectionShareList } from "../navigation/LandingPage";
import { ContentWrapper } from "../collection/CollectionView";
import { AppTitle } from "../Title";

function NewShareView(): JSX.Element {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState("");
  const { createShare } = useShares();

  function submitHandler(name: string) {
    if (name.length > 512) {
      setError("Please make the name shorter (maximum 512 characters)");
      return;
    } else {
      setError("");
    }

    setIsLoading(true);
    createShare
      .mutateAsync(name.trim())
      .then((s) => navigate(`/share/${s.id}`, { replace: true }))
      .catch((err) => console.error(err))
      .finally(() => setIsLoading(false));
  }

  return (
    <ErrorBoundary>
      <AppTitle />
      <ContentWrapper tw="gap-x-20">
        <MenuCol active>
          <CollectionShareList />
        </MenuCol>
        <MainCol active>
          <MarginWrapper isCenter hasMenu>
            <div tw="p-1 flex flex-col gap-y-8 items-start">
              <BackButton />
              <div>
                <span tw="label">New share group</span>
                <ResourceInput
                  tw="text-4xl sm:text-6xl"
                  placeholder="share name"
                  onSubmit={submitHandler}
                  submitDisabled={isLoading}
                  cta="Create"
                />
              </div>
              This will create a new share group. You can select assets and
              albums to share after creating it.
              {error && <p tw="text-danger">{error}</p>}
            </div>
          </MarginWrapper>
        </MainCol>
      </ContentWrapper>
    </ErrorBoundary>
  );
}

export default NewShareView;
