import "twin.macro";

import React, { useEffect, useState } from "react";
import {
  AlbumsResult,
  Asset,
  AssetsResult,
  ShareDetails,
  SortOrder,
} from "../../clients/types";
import { resetPageParam } from "../collection/AlbumList";
import {
  getAssets,
  getShareAlbums,
  getShareAssets,
  pageSize,
} from "../../clients/apiClient";
import { useSearchParams } from "react-router-dom";
import { getPage, Pagination } from "../generic/Pagination";
import { CheckboxRoundButton, NeuButton } from "../generic/Neu";
import { AlbumDisplayList, AssetsDisplayList } from "../generic/DisplayList";
import { useIntersectionObserver } from "../../hooks";
import { triggerFileDownload } from "../../utils";

export function ShareContents({ share }: { share: ShareDetails }) {
  const shareId = share.id;

  const { ref } = useIntersectionObserver();

  const [shareAlbums, setShareAlbums] = useState<AlbumsResult>({
    albums: [],
    albumsCount: 0,
  });

  const [shareAssets, setShareAssets] = useState<AssetsResult>({
    assets: [],
    assetsCount: 0,
  });

  const albumPages = Math.max(Math.ceil(shareAlbums.albumsCount / pageSize), 1);

  const assetPages = Math.max(Math.ceil(shareAssets.assetsCount / pageSize), 1);

  const [sortOrder, setSortOrder] = useState<SortOrder>("ASC");
  const [isAlbumsChecked, setIsAlbumsChecked] = useState(true);
  const [isAssetsChecked, setIsAssetsChecked] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const page = getPage(searchParams);

  useEffect(() => {
    resetPageParam({ searchParams, setSearchParams, name: "page" });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we intentionally don't add searchParams here, doing so would make it impossible to ever change the page!
  }, [share, isAlbumsChecked, isAssetsChecked]);

  useEffect(() => {
    if (!shareId) return;
    const abortController = new AbortController();
    (async () => {
      try {
        const fetchedAlbum = await getShareAlbums({
          shareId,
          offset: (page - 1) * pageSize,
          sortOrder,
          signal: abortController.signal,
        });
        setShareAlbums({
          albums: fetchedAlbum.albums,
          albumsCount: fetchedAlbum.albumsCount,
        });
      } catch (error) {
        if (error instanceof Error && error.name === "AbortError") {
          // No-op if request aborted
        } else {
          throw error;
        }
      }
    })();

    return () => abortController.abort();
  }, [shareId, sortOrder, page, share]);

  useEffect(() => {
    if (!shareId || !isAssetsChecked) {
      setShareAssets({ assets: [], assetsCount: 0 });
      return;
    }
    const abortController = new AbortController();

    (async () => {
      try {
        const fetchedAsset = await getShareAssets({
          shareId,
          offset: (page - 1) * pageSize,
          sortOrder,
          signal: abortController.signal,
        });

        setShareAssets({
          assets: fetchedAsset.assets,
          assetsCount: fetchedAsset.assetsCount,
        });
      } catch (error) {
        if (error instanceof Error && error.name === "AbortError") {
          // No-op if request aborted
        } else {
          throw error;
        }
      }
    })();

    return () => abortController.abort();
  }, [shareId, sortOrder, page, share, isAssetsChecked]);

  function handleSortOrder() {
    setSortOrder(sortOrder === "ASC" ? "DESC" : "ASC");
  }

  async function handleDownloadAll() {
    if (
      isDownloading ||
      !shareId ||
      (shareAssets.assetsCount === 0 && shareAlbums.albumsCount === 0)
    ) {
      return;
    }

    setIsDownloading(true);

    let totalCount = 0;
    const allAssets: Asset[] = [];

    try {
      const _shareId = shareId;
      const albums = [...shareAlbums.albums];
      // Get assets
      if (shareAssets.assetsCount > 0) {
        let offset = 0;
        const limit = 10;
        let count;
        do {
          const { assets, assetsCount } = await getShareAssets({
            shareId: _shareId,
            limit,
            offset,
          });
          if (assetsCount > 50) {
            throw new Error("Too many assets.");
          }
          if (count === undefined) {
            count = assetsCount;
            totalCount += assetsCount;
          }
          allAssets.push(...assets);
          offset += limit;
        } while (offset < count);
      }

      // Get album assets
      if (shareAlbums.albumsCount > 0) {
        outerLoop: for (const { id: albumId, collectionId } of albums) {
          let offset = 0;
          const limit = 10;
          let count;
          do {
            const { assets, assetsCount } = await getAssets({
              albumId,
              collectionId,
              limit,
              offset,
              shareId,
            });
            if (count === undefined) {
              if (assetsCount === 0) continue outerLoop;
              if (totalCount + assetsCount > 50) {
                throw new Error("Too many assets.");
              }
              count = assetsCount;
              totalCount += count;
            }
            allAssets.push(...assets);
            offset += limit;
          } while (offset < count);
        }
      }

      for (const [i, a] of allAssets.entries()) {
        triggerFileDownload(a.download_path);

        if (i > 0 && i % 10 === 0) {
          // Delay between batches is necessary in Chrome
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
      }
    } catch (error) {
      console.error("Error downloading all assets:", error); // TODO: Notify user
    } finally {
      setIsDownloading(false);
    }
  }

  return (
    <div>
      <span tw="label mb-4 mt-10">Shared albums and assets</span>
      {share.queries.length > 0 ? (
        <div tw="flex gap-4 flex-wrap">
          <NeuButton
            icon="mi-download"
            onClick={handleDownloadAll}
            disabled={isDownloading}
          >
            {isDownloading ? `Downloading...` : "Download all"}
          </NeuButton>
          <NeuButton onClick={() => handleSortOrder()} tw="mb-4">
            <i className="mi-sort" /> <span>Sort</span>
          </NeuButton>

          <CheckboxRoundButton
            id="albums"
            label="Albums"
            checked={isAlbumsChecked}
            onChange={() => setIsAlbumsChecked(!isAlbumsChecked)}
          />

          <CheckboxRoundButton
            id="assets"
            label="Assets"
            checked={isAssetsChecked}
            onChange={() => setIsAssetsChecked(!isAssetsChecked)}
          />
        </div>
      ) : (
        share.isOwner && (
          <div tw="text-2xl">
            Nothing has been shared with the group yet.
            <br />
            Create a new query to get started.
          </div>
        )
      )}
      <div tw="grid gap-4">
        {shareAlbums.albumsCount === 0 &&
        shareAssets.assetsCount === 0 &&
        share.queries.length > 0 ? (
          <div>No albums or assets to share found.</div>
        ) : (
          <div>{`${shareAlbums.albumsCount} ${
            shareAlbums.albumsCount === 1 ? "album" : "albums"
          } and ${shareAssets.assetsCount} ${
            shareAssets.assetsCount === 1 ? "asset" : "assets"
          } have been shared.`}</div>
        )}
        {isAlbumsChecked &&
          (shareAlbums.albums.length > 0 ? (
            <div>
              <AlbumDisplayList
                ref={ref}
                albums={shareAlbums.albums}
                isCondensed={false}
                page={page}
                shareId={shareId}
              />
              {(shareAssets && shareAssets.assets.length === 0) ||
              !isAssetsChecked ? (
                <Pagination pages={albumPages} tw="mt-4" />
              ) : (
                albumPages > 1 && (
                  <NeuButton
                    onClick={() => setIsAssetsChecked(false)}
                    tw="mt-4"
                  >
                    see more albums
                  </NeuButton>
                )
              )}
            </div>
          ) : null)}

        {isAssetsChecked &&
          (shareAssets.assets.length > 0 ? (
            <div>
              <AssetsDisplayList assets={shareAssets.assets} />
              {shareAlbums.albums.length === 0 || !isAlbumsChecked ? (
                <Pagination pages={assetPages} tw="mt-4" />
              ) : (
                assetPages > 1 && (
                  <NeuButton
                    onClick={() => setIsAlbumsChecked(false)}
                    tw="mt-4"
                  >
                    see more assets
                  </NeuButton>
                )
              )}
            </div>
          ) : null)}
      </div>
    </div>
  );
}
