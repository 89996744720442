import "twin.macro";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import * as reactRouterDom from "react-router-dom";
import { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react/ui";
import { ThirdPartyEmailPasswordPreBuiltUI } from "supertokens-auth-react/recipe/thirdpartyemailpassword/prebuiltui";

import CollectionView from "./components/collection/CollectionView";
import LandingView from "./components/LandingView";
import LoginView from "./components/LoginView";
import { signOut } from "supertokens-auth-react/recipe/thirdpartyemailpassword";
import { NeuButton } from "./components/generic/Neu";
import ShareView from "./components/share/ShareView";
import NewShareView from "./components/share/NewShareView";
import NewCollectionView from "./components/collection/NewCollectionView";
import { ShareAlbumView } from "./components/share/ShareAlbumView";
import { LandingPage } from "./components/navigation/LandingPage";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SuspenseWrapper } from "./components/generic/SuspenseWrapper";
import { SessionAuth } from "supertokens-auth-react/recipe/session";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <div className="App" tw="bg-bgbase h-full">
        <Router>
          <Routes>
            <Route path="/" element={<LandingView />} />
            {getSuperTokensRoutesForReactRouterDom(reactRouterDom, [
              ThirdPartyEmailPasswordPreBuiltUI,
            ])}
            <Route path="/tokenlogin" element={<LoginView />} />
            <Route
              path="/collection/"
              element={
                <PrivateRoute>
                  <SuspenseWrapper>
                    <LandingPage />
                  </SuspenseWrapper>
                </PrivateRoute>
              }
            />
            <Route
              path="/collection/new-collection"
              element={
                <PrivateRoute>
                  <SuspenseWrapper>
                    <NewCollectionView />
                  </SuspenseWrapper>
                </PrivateRoute>
              }
            />
            <Route
              path="/collection/:collectionId/new-album"
              element={
                <PrivateRoute>
                  <SuspenseWrapper>
                    <CollectionView />
                  </SuspenseWrapper>
                </PrivateRoute>
              }
            />
            <Route
              path="/collection/:collectionId"
              element={
                <PrivateRoute>
                  <SuspenseWrapper>
                    <CollectionView />
                  </SuspenseWrapper>
                </PrivateRoute>
              }
            />
            <Route
              path="/collection/:collectionId/a/:albumId"
              element={
                <PrivateRoute>
                  <SuspenseWrapper>
                    <CollectionView />
                  </SuspenseWrapper>
                </PrivateRoute>
              }
            />
            <Route
              path="/collection/:collectionId/a/:albumId/s/:assetId"
              element={
                <PrivateRoute>
                  <SuspenseWrapper>
                    <CollectionView />
                  </SuspenseWrapper>
                </PrivateRoute>
              }
            />
            <Route
              path="/share/:shareId"
              element={
                <PrivateRoute>
                  <SuspenseWrapper>
                    <ShareView />
                  </SuspenseWrapper>
                </PrivateRoute>
              }
            />
            <Route
              path="/share/new-share"
              element={
                <PrivateRoute>
                  <SuspenseWrapper>
                    <NewShareView />
                  </SuspenseWrapper>
                </PrivateRoute>
              }
            />
            <Route
              path="/share/:shareId/c/:collectionId/a/:albumId"
              element={
                <PrivateRoute>
                  <SuspenseWrapper>
                    <ShareAlbumView />
                  </SuspenseWrapper>
                </PrivateRoute>
              }
            />
            <Route
              path="/share/:shareId/c/:collectionId/a/:albumId/s/:assetId"
              element={
                <PrivateRoute>
                  <SuspenseWrapper>
                    <ShareAlbumView />
                  </SuspenseWrapper>
                </PrivateRoute>
              }
            />
          </Routes>
        </Router>
      </div>
    </QueryClientProvider>
  );
}

function PrivateRoute({ children }: { children: JSX.Element }): JSX.Element {
  return (
    <SessionAuth>
      <div tw="relative h-full">
        <LogOutButton tw="absolute top-4 right-4 z-50" />
        {children}
      </div>
    </SessionAuth>
  );
  // FIXME clean up token-based login stuff
  // const token = localStorage.getItem("token");
  // return token ? children : <Navigate to="/login" />;
}

function LogOutButton({ className }: { className?: string }): JSX.Element {
  const navigate = useNavigate();

  async function onLogout() {
    try {
      await signOut();
      navigate("/");
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <NeuButton onClick={onLogout} className={className}>
      Log out
    </NeuButton>
  );
}

export default App;
